<template>
  <div>
    <div
      v-if="!hideHeader"
      class="flex items-center justify-between gap-2 pl-2 pr-9"
    >
      <div class="flex items-center gap-2.5">
        <VDropdown
          :options="statusOptions"
          direction="sse"
        >
          <VMultiAutocompleteButton
            :label="activeStatus?.text ?? $t('statuses')"
            :count="activeStatus?.text ? 1 : 0"
            @clear="onClearStatus"
          />
        </VDropdown>

        <!-- <VDropdown :options="typeOptions">
          <div class="flex items-center">
            <div class="text-xs text-grey-2">
              {{ activeType?.text || $t('types') }}
            </div>

            <ChevronDownIcon class="chev-small inline-block" />

            <XIcon
              v-if="activeType?.value"
              class="w-3 h-3 cursor-pointer text-grey-2 inline-block"
              @click="onClickClearType"
            />
          </div>
        </VDropdown> -->
      </div>

      <div class="text-xs bg-magenta-0 text-white rounded-full px-2 py-0.5">
        <i18n-t
          keypath="count_to_do"
          tag="span"
          scope="global"
        >
          <template #count>
            <span class="font-medium">
              {{ data?.open_count ?? 0 }}
            </span>
          </template>
        </i18n-t>
      </div>
    </div>

    <div
      ref="scrollElement"
      class="stable-scroll overflow-y-auto max-h-[calc(100vh-225px)]"
    >
      <slot
        v-if="tasks?.length === 0 && !isLoading"
        name="empty"
      >
        <div class="text-green-2 px-3 py-5 font-semibold border-t">
          {{ $t('you_are_all_upto_date') }}
        </div>
      </slot>

      <div
        v-if="tasks?.length"
        class="grid pl-2 pr-7"
      >
        <component
          :is="getComponent(item)"
          v-for="(item, index) in tasks"
          :key="index"
          :task="item"
          class="border-b last:!border-b-0"
          :title-class="{ 'text-md': tableResults }"
          :title-truncate-length="tableResults ? 70 : 60"
          @update="onUpdate"
          @click="$emit('close')"
          @star="onToggleStar"
        />
      </div>

      <div class="flex items-center justify-center mt-3">
        <VLoader
          v-if="isLoading"
          class="h-5 w-5 text-blue-3 opacity-70"
          :stroke-width="2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { watch, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import store from '@/store';
import ALL_OPTIONS from '@/constants/options';
import { helpers } from '@/utils/helpers';
// Composables
import useInfiniteScrolling from '@/composables/useInfiniteScrolling';
import useRequest from '@/composables/useRequest';
import useToast from '@/composables/useToast';
// Components
import { ChevronDownIcon } from '@heroicons/vue/solid';
import { XIcon } from '@heroicons/vue/outline';
import VDropdown from '@/components/VDropdown';
import SurveyTask from '@/components/templates/tasks/SurveyTask';
import JobReviewTask from '@/components/templates/tasks/JobReviewTask';
import JobCollabTask from '@/components/templates/tasks/JobCollabTask';
import SignoffTask from '@/components/templates/tasks/SignoffTask';
import GoalTask from '@/components/templates/tasks/GoalTask';
import InterviewOpening from '@/components/templates/tasks/InterviewOpening';
import InterviewInvite from '@/components/templates/tasks/InterviewInvite';
import ReviewConductAssessmentSubject from '@/components/templates/tasks/ReviewConductAssessmentSubject';
import VLoader from '@/components/VLoader';
import VMultiAutocompleteButton from '@/components/inputs/VMultiAutocompleteButton';
// Services
import TaskService from '@/services/hrsg/platform/TaskService';

export default {
  components: {
    SignoffTask,
    SurveyTask,
    JobReviewTask,
    JobCollabTask,
    GoalTask,
    InterviewOpening,
    InterviewInvite,
    ReviewConductAssessmentSubject,
    ChevronDownIcon,
    VMultiAutocompleteButton,
    XIcon,
    VDropdown,
    VLoader
  },
  props: {
    hideHeader: {
      type: Boolean,
      default: false
    },
    tableResults: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'update', 'update:todo-count'],
  setup (props, context) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { page, hasAllResults, isLoading, scroll } = useInfiniteScrolling();
    const { isSuccessResponse } = useRequest();
    const { showToast } = useToast();

    // Constants
    const ITEMS_PER_PAGE = 50;

    // Data
    const data = ref([]);
    const scrollElement = ref();

    // Computed
    const activeStatus = computed(() => store.getters['tasks/status']);
    const activeType = computed(() => store.getters['tasks/type']);
    const activeTerm = computed(() => store.getters['tasks/term']);
    const activeStarred = computed(() => store.getters['tasks/starred']);
    const updatedStars = computed(() => store.getters['tasks/updatedStars']);
    const tasks = computed(() => {
      return data.value?.task_list?.map(x => {
        x.icon = getIcon(x?.type);
        x.starred = updatedStars.value?.[x.id] ?? x.starred;
        return x;
      }) ?? [];
    });
    const todoCount = computed(() => {
      return tasks?.value?.filter(task => ['Active', 'Open'].includes(task?.status)).length || 0;
    });

    const statusOptions = computed(() => {
      const setActiveTaskStatus = option => {
        store.dispatch('tasks/setStatus', option);
      };

      return [
        {
          text: t('to_do'),
          value: 'Open',
          click: option => {
            setActiveTaskStatus(option);
          }
        },
        {
          text: t('done'),
          value: 'Completed',
          click: option => {
            setActiveTaskStatus(option);
          }
        },
        {
          text: t('cancelled'),
          value: 'Cancelled',
          click: option => {
            setActiveTaskStatus(option);
          }
        }
      ];
    });

    const typeOptions = computed(() => {
      const setActiveTaskType = option => {
        store.dispatch('tasks/setType', option);
      };

      return [
        {
          text: t('job_input'),
          value: 'JOB_INPUT',
          click: option => {
            setActiveTaskType(option);
          }
        },
        {
          text: t('goal_feedback'),
          value: 'GOAL_FEEDBACK',
          click: option => {
            setActiveTaskType(option);
          }
        },
        {
          text: t('job_signoff'),
          value: 'JOB_SIGNOFF',
          click: option => {
            setActiveTaskType(option);
          }
        },
        {
          text: t('scheduled_review'),
          value: 'SCHEDULED_REVIEW',
          click: option => {
            setActiveTaskType(option);
          }
        },
        {
          text: t('take_survey'),
          value: 'TAKE_SURVEY',
          click: option => {
            setActiveTaskType(option);
          }
        }
      ];
    });

    // Methods
    const getData = async () => {
      isLoading.value = true;

      store.dispatch('tasks/updateStars', false);
      const status = activeStatus.value?.value ?? (props.tableResults ? 'Open,Completed,Cancelled' : '');

      let queryParams = {
        page: page.value,
        ro_p: ITEMS_PER_PAGE,
        status
      };

      if (props.tableResults) {
        queryParams = {
          ...queryParams,
          type: activeType.value?.value ?? '',
          search_term: activeTerm.value?.value ?? '',
          starred: activeStarred.value?.value ?? ''
        };
      }

      const response = await TaskService.index(queryParams);
      isLoading.value = false;
      page.value++;
      store.dispatch('user/setTasksCount', response?.open_count);

      context.emit('update', response);
      return response;
    };

    const getComponent = item => {
      const types = {
        JOB_INPUT: 'JobCollabTask',
        GOAL_FEEDBACK: 'GoalTask',
        JOB_SIGNOFF: 'SignoffTask',
        JOB_REVIEW: 'JobReviewTask',
        TAKE_SURVEY: 'SurveyTask',
        OPENING: 'InterviewOpening',
        INTERVIEW: 'InterviewInvite',
        CONDUCT_ASSESSMENT_SUBJECT: 'ReviewConductAssessmentSubject'
      };

      return types[item?.type] ?? '';
    };

    const getIcon = type => {
      const types = {
        JOB_INPUT: 'description',
        GOAL_FEEDBACK: 'sports_score',
        JOB_SIGNOFF: 'description',
        SCHEDULED_REVIEW: 'description',
        JOB_REVIEW: 'description',
        TAKE_SURVEY: 'inventory',
        OPENING: 'measuring_tape',
        INTERVIEW: 'measuring_tape',
        CONDUCT_ASSESSMENT_SUBJECT: 'measuring_tape'
      };

      return types[type] ?? '';
    };

    const onUpdate = async event => {
      await TaskService.update(event.id, event.data);
      page.value = 1;
      hasAllResults.value = false;
      data.value = await getData();
      context.emit('update:todo-count', todoCount.value);
    };

    const onClearStatus = () => {
      store.dispatch('tasks/setStatus', '');
    };

    const onClickClearType = () => {
      store.dispatch('tasks/setType', '');
    };

    const onToggleStar = async (id) => {
      const task = data.value.task_list.find(x => x.id === id);
      const originalValue = task.starred;
      task.starred = helpers.isYes(task.starred) ? ALL_OPTIONS.NO.value : ALL_OPTIONS.YES.value;

      const response = await TaskService.toggleStar(id);
      if (isSuccessResponse(response)) {
        store.dispatch('tasks/updateStars', { [id]: task.starred });
      } else {
        task.starred = originalValue;
        showToast(t('general_error'), 'Failure');
      }
    };

    // Watchers
    watch([activeStatus, activeType, activeTerm, activeStarred], async () => {
      page.value = 1;
      hasAllResults.value = false;
      data.value = await getData();
      context.emit('update:todo-count', todoCount.value);
    }, {
      immediate: true
    });

    watch(updatedStars, async newValue => {
      if (Object.keys(newValue).length && props.tableResults && helpers.isYes(activeStarred.value.value)) {
        store.dispatch('tasks/updateStars', {});
        page.value = 1;
        hasAllResults.value = false;
        data.value = await getData();
        context.emit('update:todo-count', todoCount.value);
      }
    });

    watch(scrollElement, newValue => {
      newValue.style.maxHeight = `${((window.innerHeight - 115) * 85) / 100}px`;

      scroll(newValue, async () => {
        const response = await getData();

        if (response?.task_list.length < ITEMS_PER_PAGE) {
          hasAllResults.value = true;
        }

        data.value.task_list.push(...response?.task_list ?? []);
        context.emit('update:todo-count', todoCount.value);
      });
    });

    return {
      tasks,
      isLoading,
      scrollElement,
      data,
      activeStatus,
      activeType,
      typeOptions,
      statusOptions,
      getComponent,
      onUpdate,
      onToggleStar,
      onClearStatus,
      onClickClearType
    };
  }
};
</script>
