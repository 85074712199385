export default {
  TO_DO: {
    localeKey: ['to_do'],
    value: 'To do'
  },
  REQUESTED: {
    localeKey: ['requested'],
    value: 'Requested'
  },
  CURRENT: {
    localeKey: ['current'],
    value: 'Current'
  },
  EXPIRED: {
    localeKey: ['expired'],
    value: 'Expired'
  },
  YES: {
    localeKey: ['yes'],
    value: 'Yes'
  },
  NO: {
    localeKey: ['no'],
    value: 'No'
  },
  ACTIVE: {
    localeKey: ['active'],
    value: 'Active'
  },
  INACTIVE: {
    localeKey: ['active'],
    value: 'Inactive'
  },
  ON_LEAVE: {
    localeKey: ['on_leave'],
    value: 'On Leave'
  },
  DEACTIVATED: {
    localeKey: ['deactivated'],
    value: 'Deactivated'
  },
  SUSPENDED: {
    localeKey: ['suspended'],
    value: 'Suspended'
  },
  DIRECT: {
    localeKey: ['direct'],
    value: 'Direct'
  },
  SSO: {
    localeKey: ['sso'],
    value: 'SSO'
  },
  ENABLED: {
    localeKey: ['enabled'],
    value: 'Enabled'
  },
  DISABLED: {
    localeKey: ['disabled'],
    value: 'Disabled'
  },
  DEPARTURE: {
    localeKey: ['departure'],
    value: 'Departure'
  },
  OTHER: {
    localeKey: ['other'],
    value: 'Other'
  },
  SELECT_PLACEHOLDER: {
    localeKey: ['select'],
    value: null
  },
  DRAFT: {
    localeKey: ['draft'],
    value: 'Draft'
  },
  PUBLISHED: {
    localeKey: ['published'],
    value: 'Published'
  },
  ARCHIVED: {
    localeKey: ['archived'],
    value: 'Archived'
  },
  PUBLISH: {
    localeKey: ['publish'],
    value: 'publish'
  },
  EDIT: {
    localeKey: ['edit'],
    value: 'edit'
  },
  MOVE: {
    localeKey: ['move'],
    value: 'move'
  },
  ARCHIVE: {
    localeKey: ['archive'],
    value: 'archive'
  },
  DELETE: {
    localeKey: ['delete'],
    value: 'delete'
  },
  DRAFT_COPY: {
    localeKey: ['draft_copy'],
    value: 'Draft Copy'
  },
  TURN_ON: {
    localeKey: ['turn_on'],
    value: 'turn_on'
  },
  TURN_OFF: {
    localeKey: ['turn_off'],
    value: 'turn_off'
  },
  SELECT: {
    localeKey: ['single_select'],
    value: 'SELECT'
  },
  MULTI_SELECT: {
    localeKey: ['multi_select'],
    value: 'MULTI-SELECT'
  },
  TEXTBOX: {
    localeKey: ['textbox'],
    value: 'TEXTBOX'
  },
  DONE: {
    localeKey: ['done'],
    value: 'Done'
  },
  TODO: {
    localeKey: ['todo'],
    value: 'To Do'
  },
  GOOD_TO_GO: {
    localeKey: ['good_to_go'],
    value: 'Good to go'
  },
  NEEDS_WORK: {
    localeKey: ['needs_work'],
    value: 'Needs more work'
  },
  RESIGNED: {
    localeKey: ['resigned'],
    value: 'Resigned'
  },
  PROMOTED: {
    localeKey: ['promoted'],
    value: 'Promoted'
  },
  MOVED_LATERALLY: {
    localeKey: ['moved_laterally'],
    value: 'Lateral Move'
  },
  DEMOTED: {
    localeKey: ['demoted'],
    value: 'Demoted'
  },
  FIRED: {
    localeKey: ['fired'],
    value: 'Fired'
  },
  RETIRED: {
    localeKey: ['retired'],
    value: 'Retired'
  },
  TITLE_CHANGE: {
    localeKey: ['title_change_only'],
    value: 'Title Change'
  },
  MANAGER: {
    localeKey: ['managers'],
    value: 'Manager'
  },
  OWNER: {
    localeKey: ['owners'],
    value: 'Owner'
  },
  REVIEWER: {
    localeKey: ['reviewers'],
    value: 'Reviewer'
  },
  EDITOR: {
    localeKey: ['editors'],
    value: 'Editor'
  },
  NONE: {
    localeKey: ['none'],
    value: 'none'
  },
  JOB_UPDATE_NO_UPDATE: {
    localeKey: ['job_update_no_update_text'],
    value: 'no_update'
  },
  JOB_UPDATE_CONTROLLED: {
    localeKey: ['job_update_controlled_text'],
    localeSubKey: ['job_update_controlled_subtext'],
    value: 'job_update_controlled'
  },
  JOB_UPDATE_SILENT: {
    localeKey: ['job_update_silent_text'],
    localeSubKey: ['job_update_silent_subtext'],
    value: 'job_update_silent'
  },
  DEFINE_LIGHT: {
    localeKey: ['define_light'],
    value: 'Define Light'
  },
  DEFINE_FULL: {
    localeKey: ['define_full'],
    value: 'Define Full'
  },
  GROW_ACCESS: {
    localeKey: ['grow_access'],
    value: 'Grow Access'
  },
  GROW: {
    localeKey: ['grow'],
    value: 'Grow'
  },
  LIKE: {
    localeKey: ['likes'],
    value: 'Like'
  },
  DISLIKE: {
    localeKey: ['dislikes'],
    value: 'Dislike'
  },
  ITEM: {
    localeKey: ['item'],
    value: 'Item'
  },
  SUBHEADING: {
    localeKey: ['subheadings'],
    value: 'Subheading'
  },
  PROPERTY: {
    localeKey: ['properties'],
    value: 'Property'
  },
  EMAIL: {
    localeKey: ['email'],
    value: 'Email'
  },
  SCHEDULED: {
    localeKey: ['scheduled'],
    value: 'Scheduled'
  },
  LIVE: {
    localeKey: ['live'],
    value: 'Live'
  },
  CLOSED: {
    localeKey: ['closed'],
    value: 'Closed'
  },
  WELCOME_PAGE: {
    localeKey: ['welcome_page'],
    value: 'Welcome Page'
  },
  INTRO_PAGE: {
    localeKey: ['intro_page'],
    value: 'Intro page'
  },
  DEPRECATED: {
    localeKey: ['deprecated'],
    value: 'Deprecated'
  },
  OFF: {
    localeKey: ['off'],
    value: 'Off'
  },
  THIRTY_DAYS_VALID: {
    localeKey: ['30_days_valid'],
    value: '30 Days Valid'
  },
  EVERY_LOGIN: {
    localeKey: ['every_login'],
    value: 'Every Login'
  },
  IN_PROGRESS: {
    localeKey: ['in_progress'],
    value: 'In Progress'
  },
  PENDING_SHARING: {
    localeKey: ['pending_sharing'],
    value: 'Pending Sharing'
  },
  PENDING_ACKNOWLEDGEMENT: {
    localeKey: ['pending_acknowledgement'],
    value: 'Pending Acknowledgement'
  },
  LAUNCHED: {
    localeKey: ['launched'],
    value: 'Launched'
  },
  CANCELLED: {
    localeKey: ['cancelled'],
    value: 'Cancelled'
  },
  DISPATCHED: {
    localeKey: ['dispatched'],
    value: 'Dispatched'
  },
  PENDING: {
    localeKey: ['pending'],
    value: 'Pending'
  },
  AZURE: {
    localeKey: ['microsoft'],
    value: 'azure'
  },
  OKTA: {
    localeKey: ['okta'],
    value: 'okta'
  },
  GOOGLE: {
    localeKey: ['google'],
    value: 'google'
  },
  COMPLETED: {
    localeKey: ['completed'],
    value: 'Completed'
  },
  ADMINISTRATOR: {
    localeKey: ['admin'],
    value: 'ADMINISTRATOR'
  },
  REGULAR: {
    localeKey: ['regular'],
    value: 'REGULAR'
  },
  CURRENT_JOB: {
    localeKey: ['current_jobs'],
    value: 'Current Job'
  },
  SPECIFIC_JOB: {
    localeKey: ['specific_jobs'],
    value: 'Specific Job'
  },
  SPECIFIC_QUESTIONS: {
    localeKey: ['specific_questions', 2],
    value: 'Specific Questions'
  }
};
