import CRUDService from '@/services/hrsg/CRUDService';

export default class ScaleService extends CRUDService {
  constructor (endpoint = 'administration.scales') {
    super(endpoint);
  }

  async getScaleByHandle (handle) {
    const scale = await super.index({
      ro_f: JSON.stringify([
        {
          f: ['handle'],
          c: 'LIKE',
          v: handle
        }
      ])
    });

    return scale[0] ?? false;
  }

  async reorder (data) {
    return await this.request({
      endpoint: `${this.endpoint}.reorder`,
      pathParams: this.getPathvars(),
      data
    });
  }

  async setTarget (data) {
    return await this.request({
      endpoint: `${this.endpoint}.set-target`,
      data
    });
  }
}
