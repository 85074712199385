// Constants
import ALL_OPTIONS from '@/constants/options';

export default {
  New: 'green',
  [ALL_OPTIONS.LIVE.value]: 'green',
  [ALL_OPTIONS.RETIRED.value]: 'orange',
  [ALL_OPTIONS.TODO.value]: 'orange',
  Updated: 'purple',
  [ALL_OPTIONS.ON_LEAVE.value]: 'grey',
  [ALL_OPTIONS.ACTIVE.value]: 'green',
  [ALL_OPTIONS.ARCHIVED.value]: 'grey',
  [ALL_OPTIONS.DEACTIVATED.value]: 'grey',
  [ALL_OPTIONS.INACTIVE.value]: 'grey',
  [ALL_OPTIONS.DISABLED.value]: 'grey',
  [ALL_OPTIONS.DEPRECATED.value]: 'grey',
  [ALL_OPTIONS.DRAFT.value]: 'orange',
  [ALL_OPTIONS.TO_DO.value]: 'orange',
  [ALL_OPTIONS.SCHEDULED.value]: 'orange',
  [ALL_OPTIONS.DRAFT_COPY.value]: 'orange',
  [ALL_OPTIONS.REQUESTED.value]: 'orange',
  [ALL_OPTIONS.ENABLED.value]: 'green',
  [ALL_OPTIONS.CURRENT.value]: 'green',
  [ALL_OPTIONS.PUBLISHED.value]: 'green',
  [ALL_OPTIONS.SUSPENDED.value]: 'red',
  [ALL_OPTIONS.EXPIRED.value]: 'red',
  Reviewed: 'blue',
  [ALL_OPTIONS.CLOSED.value]: 'grey',
  [ALL_OPTIONS.CANCELLED.value]: 'grey',
  [ALL_OPTIONS.DISPATCHED.value]: 'grey',
  [ALL_OPTIONS.COMPLETED.value]: 'green',
  [ALL_OPTIONS.PENDING.value]: 'grey',
  [ALL_OPTIONS.LAUNCHED.value]: 'blue',
  [ALL_OPTIONS.IN_PROGRESS.value]: 'green',
  [ALL_OPTIONS.PENDING_SHARING.value]: 'green',
  [ALL_OPTIONS.PENDING_ACKNOWLEDGEMENT.value]: 'green'
};
