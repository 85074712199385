import CRUDService from '@/services/hrsg/CRUDService';

export class AssessmentService extends CRUDService {
  constructor () {
    super('reviews.assessments');
  }

  async getQuestionnaire () {
    const response = await this.request({
      endpoint: `${this.endpoint}.questionnaire`,
      pathParams: this.getPathvars()
    });

    return response?.payload?.data ?? [];
  }

  async saveResponse (data) {
    const response = await this.request({
      endpoint: `${this.endpoint}.save-response`,
      pathParams: this.getPathvars(),
      data
    });

    return response?.payload?.data ?? [];
  }

  async submitRating () {
    const response = await this.request({
      endpoint: `${this.endpoint}.submit`,
      pathParams: this.getPathvars()
    });

    return response?.payload?.data ?? [];
  }
}

export default new AssessmentService();
